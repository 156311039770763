import React from 'react'
import dayjs from 'dayjs';
import { useActivityLogApi, useTeacherDetailApi } from '../../../apis/teacherApi';
import { TimelineUI } from '../../../components/TimelineUI';

const ActivityLog = () => {

    const { data: staff } = useTeacherDetailApi();

    const { data } = useActivityLogApi({
        type: "STAFF",
        id: staff.id,
    }) || {};


    const timelineItems = data?.map((item) => ({
        key: item.id,
        date: dayjs(item.updatedTime).format('YYYY-MM-DD HH:mm:ss'),
        title: item.activityMessage,
        activityStatus: item.activityStatus,
        activityBy: item.activityBy
    }));


    return (
        <TimelineUI timelineItems={timelineItems} />
    )
}

export default ActivityLog