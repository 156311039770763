import { Carousel, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { carousel1, carousel2, carousel3, clzLogo } from '../../assets';
import ButtonCustom from '../../components/common/Button/ButtonCustom';
import { useDispatch } from "react-redux";
import { storeData } from '../../redux/storage/action';
import { useNavigate } from 'react-router-dom';
import { useLoginCollegeUrl } from '../../apis/authApi';


const ClzUrl = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clzUrl, setclzUrl] = useState(null)
    const [disable, setDisable] = useState(false)

    const { data, status } = useLoginCollegeUrl({
        "loginCollegeUrl": clzUrl
    });

    useEffect(() => {
        if (status === "pending") { return }
        if (data) { navigate(`/login`, { replace: true }); }
        else { message.error("College with such login url not found"); }
    }, [data, navigate, status])



    const handleSubmitLogin = (val) => {
        setDisable(true)
        dispatch(storeData(val.url))
        setclzUrl(val.url)

        setTimeout(() => {
            setDisable(false)
        }, 1000);
    }

    return (
        <div className='flex justify-center items-stretch w-full h-screen'>
            <div className='w-[70%] flex flex-col justify-center items-center'>
                <div>
                    <img className="w-[10rem] h-[10rem] object-contain" src={clzLogo} alt='College' />
                </div>
                <p className="text-xl font-medium">Welcome To SmartRTO</p>
                <p className="text-gray-500 text-base text-center mb-4 bg-white">
                    Please enter your college sub-domain to continue with SmartRTO.
                </p>

                <Form
                    layout="vertical"
                    style={{ maxWidth: "20rem", width: "100%" }}
                    onFinish={handleSubmitLogin}
                >
                    <Form.Item
                        name={"url"}
                        className="w-full"
                        rules={[{ required: true, message: "College Url is required!", },]}
                    >
                        <Input
                            placeholder="College Url"
                            size="large"
                            addonAfter=".smartrto.com"
                        />
                    </Form.Item>


                    <div className='flex justify-center items-center'>
                        <ButtonCustom
                            type='submit'
                            disabled={disable}
                        >
                            Continue
                        </ButtonCustom>
                    </div>

                </Form>
            </div>

            <div className='w-[30%]'>
                <Carousel autoplay={true}>
                    <div className='w-full h-screen'>
                        <img
                            className="text-white leading-[160px] text-center bg-[#364d79] object-contain w-full h-full"
                            src={carousel1}
                            alt="Carousel1"
                        />
                        <h1 className="text-white text-center leading-loose">
                            Manage Data
                        </h1>
                    </div>
                    <div className='w-full h-screen'>
                        <img
                            className="text-white leading-[160px] text-center bg-[#364d79] object-contain w-full h-full"
                            src={carousel2}
                            alt="Carousel2"
                        />
                        <h1 className="text-white leading-[160px] text-center">
                            Automation
                        </h1>
                    </div>
                    <div className='w-full h-screen'>
                        <img
                            className="text-white leading-[160px] text-center bg-[#364d79] object-contain w-full h-full"
                            src={carousel3}
                            alt="Carousel3"
                        />
                        <h1 className="text-white leading-[160px] text-center">
                            Reporting
                        </h1>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}

export default ClzUrl