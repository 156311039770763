
const initialState = {
    storage: null,
    imageLink: []
}

const storageReducer = (state = initialState, action) => {
    switch (action.type) {

        case "STORE_USER_RECORDS":
            return {
                ...state,
                storage: action.payload
            }

        case "CLEAN_USER_RECORDS":
            return {
                ...state,
                storage: null
            }

        case "STORE_FILE_OR_IMAGE_INFO":
            return {
                ...state,
                imageLink: [...state.imageLink, action.payload]
            }

        case "CLEAN_FILE_OR_IMAGE_INFO":
            return {
                ...state,
                imageLink: []
            }
        case "REMOVE_SPECIFIC_FILE":
            return {
                ...state,
                imageLink: state.imageLink.filter((_, idx) => idx !== action.payload)
            }

        default:
            return state
    }
}

export default storageReducer