import { Pagination } from "antd";
import React from "react";

export default function PaginationElement({
    currentPage,
    totalPage,
    pageSize,
    setCurrentPage,
    setPageSize,
}) {
    return (
        <Pagination
            className="mt-6 flex justify-end flex-row"
            showSizeChanger
            total={totalPage * pageSize}
            current={currentPage}
            onChange={(value, size) => {
                setCurrentPage(value);
                setPageSize(size);
            }}
        />
    );
}
