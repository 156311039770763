import { Card, Divider, Skeleton } from "antd";
import React from "react";

export default function DetailSkeleton() {
    return (
        <Card className="detail-drawerStyle">
            <div className="flex flex-col gap-4">
                <div className="flex items-end flex-row gap-4">
                    <Skeleton.Avatar
                        style={{ borderRadius: "1.5rem !important" }}
                        active={true}
                        size={152}
                        shape={"square"}
                    />
                    <div className="flex flex-row gap-2">
                        <Skeleton.Button
                            active={true}
                            style={{ width: "6rem", height: "1.8rem" }}
                        />
                        <Skeleton.Button
                            active={true}
                            style={{ width: "4rem", height: "1.8rem" }}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    <Skeleton.Button
                        style={{ width: "7rem", height: "1.2rem", borderRadius: "0.5rem" }}
                    />
                    <Skeleton.Button
                        size={"small"}
                        style={{ width: "5rem", height: "1.2rem", borderRadius: "0.5rem" }}
                    />
                    <Skeleton.Button
                        size={"small"}
                        style={{ width: "7rem", height: "1.2rem", borderRadius: "0.5rem" }}
                    />
                    <Skeleton.Button
                        size={"small"}
                        style={{ width: "6rem", height: "1.2rem", borderRadius: "0.5rem" }}
                    />
                    <Skeleton.Button
                        size={"small"}
                        style={{ width: "6rem", height: "1.2rem", borderRadius: "0.5rem" }}
                    />
                </div>
                <Divider className="m-0" />
                <Card>
                    <Skeleton active />
                </Card>
            </div>
        </Card>
    );
}
