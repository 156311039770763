import React, { useState } from "react";
import { Card, Radio, Table, Tag } from "antd";
import { useDiaryApi, useTeacherDetailApi } from "../../../apis/teacherApi";
import { displayDate } from "../../../utils/helpers";
import PageHeader from "../../../components/PageStyle/PageHeader";
import PaginationElement from "../../../components/PaginationElement/PaginationElement";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";


const Diary = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const pageCount = 0;
    const sort = "DESC";
    const sortParameter = "id";


    const [status, setStatus] = useState("ALL");

    const { data: staff } = useTeacherDetailApi();

    const { data, isPending } = useDiaryApi({
        currentPage: currentPage - 1,
        pageCount: pageCount,
        pageSize: pageSize,
        sort: sort,
        sortParameter: sortParameter,
        status: status,
        id: staff.id
    }) || {};

    const DiaryList = data?.data || [];
    const totalPage = data?.totalPage || 0;


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 100,
            align: "center",
        },
        {
            title: "Date/Time Log",
            dataIndex: "date",
            width: 200,
            align: "center",
            render: (date) => displayDate(date)
        },
        {
            title: "Added/Commented By",
            dataIndex: "commentBy",
            width: 150,
            align: "center",
        },
        {
            title: "Type",
            dataIndex: "type",
            width: 100,
            align: "center",
        },
        {
            title: "Subject",
            dataIndex: "subject",
            width: 150,
            align: "center",
        },
        {
            title: "Comment Log",
            dataIndex: "comment",
            width: 200,
            align: "center",
        },
        {
            title: "File Attachment",
            dataIndex: "imageUrl",
            width: 150,
            align: "center",
            render: (image) =>
                image ? (
                    <a target="_blank" rel="noreferrer" href={image}>view</a>
                ) : (null)
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 100,
            align: "center",
        },
        {
            title: "Is Visible",
            dataIndex: "isLogEnabled",
            width: 100,
            align: "center",
            fixed: "right",
            render: (status) =>
                status ? (
                    <Tag className="active-tag">Active</Tag>
                ) : (
                    <Tag className="inactive-tag">Inactive</Tag>
                ),
        },
    ];


    return (
        <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
            <PageHeader>

                <div className="fxRAc" style={{ gap: "1rem" }}>
                    <div className="title">Communication Log (Diary)</div>
                </div>
                <Radio.Group
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value);
                    }}
                >
                    <Radio.Button value="ALL" className="rounded">
                        All
                    </Radio.Button>
                    <Radio.Button value="ACTIVE" className="rounded">
                        Active
                    </Radio.Button>
                    <Radio.Button value="INACTIVE" className="rounded">
                        Inactive
                    </Radio.Button>
                </Radio.Group>


            </PageHeader>
            <div
                className="fxC"
                style={{
                    marginTop: "1rem",
                    overflow: "hidden"
                }}
            >
                {isPending ? (
                    <TableSkeleton />
                ) : (
                    <Table
                        columns={columns.map(val => ({
                            ...val,
                            title: <div className="whitespace-nowrap min-w-fit px-4">{val.title}</div>,
                        }))}
                        dataSource={DiaryList}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: true }}
                        bordered
                    />
                )}
            </div>
            <PaginationElement
                currentPage={currentPage}
                pageSize={pageSize}
                totalPage={totalPage}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
            />
        </Card>
    );
};

export default Diary;
