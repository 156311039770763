import React from "react";
import { Card, Table } from "antd";
import { useDocumentApi, useTeacherDetailApi } from "../../../apis/teacherApi";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import PageHeader from "../../../components/PageStyle/PageHeader";
import { displayDate } from "../../../utils/helpers";



const Documents = () => {


    const { data: staff } = useTeacherDetailApi();

    const { data, isPending } = useDocumentApi({
        id: staff?.id,
    }) || {};

    const studentDocLisst = data?.data || [];


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 100,
            align: "center",
        },
        {
            title: "Name",
            width: 100,
            align: "center",
            render: (dataObj) => <a className='font-bold text-blue-400' target="_blank" rel="noreferrer" href={dataObj?.uploadedDocument}>{dataObj?.documentName}</a>
        },
        {
            title: "Expiry Date",
            dataIndex: "expiryDate",
            width: 100,
            align: "center",
            render: (date) => displayDate(date)
        },
        {
            title: "Size",
            dataIndex: "size",
            width: 100,
            align: "center",
            render: (size) => size ? `${Math.round((parseInt(size) / 1024))} kb` : "N/A"
        },
        {
            title: "Extension",
            dataIndex: "extension",
            width: 100,
            align: "center",
        },
        {
            title: "Last Modified",
            dataIndex: "uploadedOn",
            width: 100,
            align: "center",
            render: (date) => displayDate(date)
        },
    ];


    return (
        <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
            <PageHeader>
                <div className="fxRAc" style={{ gap: "1rem" }}>
                    <div className="title">Documents</div>
                </div>
            </PageHeader>
            <div
                className="fxC"
                style={{
                    marginTop: "1rem",
                    overflow: "hidden"
                }}
            >

                {isPending ? (
                    <div className='overflow-hidden'>
                        <TableSkeleton />
                    </div>
                ) : (
                    <Table
                        columns={columns.map(val => ({
                            ...val,
                            title: <div className="whitespace-nowrap min-w-fit px-4">{val.title}</div>,
                        }))}
                        dataSource={studentDocLisst}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: true }}
                        bordered
                    />
                )}
            </div>
        </Card>
    );
};

export default Documents;
