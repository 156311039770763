import { useLocation, Navigate, Outlet } from "react-router-dom"
import Cookies from "js-cookie";


const RequireAuth = () => {

    const location = useLocation()
    const accessToken = Cookies.get("access_token_teacher") ?? false

    return (
        accessToken ?
            <Outlet />
            :
            <Navigate to="/login/college" state={{ from: location.pathname }} replace />
    )
}
export default RequireAuth