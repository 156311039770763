import { Card, Divider } from "antd";
import React from "react";
import { useTeacherDetailApi } from "../../../apis/teacherApi";


const Profile = () => {

    const { data: staff } = useTeacherDetailApi();

    const detailFun = (title, value) => {
        return (
            <div style={{ width: "100%" }}>
                <div className="flex justify-between items-center">
                    <span className="font-semibold">{title}:</span><span>{value}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            </div>
        );
    };
    return (
        <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
            {detailFun("Title", staff?.title)}
            {detailFun("First Name", staff?.firstName)}
            {detailFun("Last Name", staff?.lastName)}
            {detailFun("Username", staff?.userName)}
            {detailFun("College Email", staff?.collegeEmail)}
            {detailFun("Personal Email", staff?.email)}
            {detailFun("Mobile", staff?.mobileNo)}
            {detailFun("Phone", staff?.phoneNo)}
            {detailFun("Position", staff?.position)}
            {detailFun("Staff Number", staff?.staffNumber)}
            {detailFun("Country", staff?.country)}
            {detailFun("State", staff?.state)}
            {detailFun("City", staff?.city)}
            {detailFun("Post Code", staff?.postCode)}
            {detailFun("Address", staff?.address)}
            {detailFun("Emergency Contact Name", staff?.emergencyContactName)}
            {detailFun("Emergency Contact Number", staff?.emergencyContactNumber)}
            {detailFun("Signatory Text", staff?.signatoryText)}
            {detailFun("Is Teacher", staff?.isTeacher ? "Yes" : "No")}
            {detailFun("Is Super Admin", staff?.isSuperAdmin ? "Yes" : "No")}
            {detailFun("Is Locked", staff?.isLocked ? "Yes" : "No")}
            {detailFun("Active", staff?.active ? "Yes" : "No")}
        </Card>
    );
};

export default Profile;
