import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstance } from "./baseConfig";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { handleError, showMessage } from "../utils/helpers";
import { cleanFile } from "../redux/storage/action";


const teacherDetailApi = async (id) => {
    const url = `/v1/api/staffs/get-by-id/${id}`;
    const response = await axiosInstance.get(url);
    return response.data?.data;
};

export const useTeacherDetailApi = () => {
    const id = Cookies.get("tchrId")
    return useQuery({
        queryFn: () => teacherDetailApi(id),
        queryKey: ["teacherDetailApi", id],
        staleTime: 1000 * 60 * 5
    })
};

/****************************************************************************************************************** */

const activityLogApi = async (params) => {
    const url = `/v1/api/college/activity-log/get-all`;
    const response = await axiosInstance.get(url, { params });
    return response.data?.data;
};

export const useActivityLogApi = (params) => {
    return useQuery({
        queryFn: () => activityLogApi(params),
        queryKey: ["activityLogApi", params],
        enabled: !!params
    })
};

/****************************************************************************************************************** */

const matrixApi = async (params) => {
    const url = `/v1/api/college/teacher/matrix/get-all`;
    const response = await axiosInstance.get(url, { params });
    return response.data?.data;
};

export const useMatrixApi = (params) => {
    return useQuery({
        queryFn: () => matrixApi(params),
        queryKey: ["matrixApi", params],
        enabled: !!params
    })
};

/****************************************************************************************************************** */

const diaryApi = async ({ id, ...params }) => {
    const url = `/v1/api/staffsdiary/get-all/${id}`;
    const response = await axiosInstance.get(url, { params });
    return response.data?.data;
};

export const useDiaryApi = (params) => {
    return useQuery({
        queryFn: () => diaryApi(params),
        queryKey: ["diaryApi", params],
        enabled: !!params
    })
};

/****************************************************************************************************************** */

const documentApi = async ({ id, ...params }) => {
    const url = `/v1/api/staffs/document/get-all/${id}`;
    const response = await axiosInstance.get(url, { params });
    return response.data?.data;
};

export const useDocumentApi = (params) => {
    return useQuery({
        queryFn: () => documentApi(params),
        queryKey: ["documentApi", params],
        enabled: !!params
    })
};

/****************************************************************************************************************** */

const leaveInfoApi = async ({ id, ...params }) => {
    const url = `/v1/api/college/staff/leave-info/get-all-of-staff/${id}`;
    const response = await axiosInstance.get(url, { params });
    return response.data?.data;
};

export const useLeaveInfoApi = (params) => {
    return useQuery({
        queryFn: () => leaveInfoApi(params),
        queryKey: ["leaveInfoApi", params],
        enabled: !!params
    })
};

/****************************************************************************************************************** */

const proDevApi = async ({ id, ...params }) => {
    const url = `/v1/api/staffs/pd/get-all/${id}`;
    const response = await axiosInstance.get(url, { params });
    return response.data?.data;
};

export const useProDevApi = (params) => {
    return useQuery({
        queryFn: () => proDevApi(params),
        queryKey: ["proDevApi", params],
        enabled: !!params
    })
};
/****************************************************************************************************************** */

const sendEmailApi = async (body) => {
    const url = `/v1/api/staffs/send-email-in-bulk`;
    const response = await axiosInstance.post(url, body);
    return response.data;
};

export const useSendEmailApi = () => {

    const dispatch = useDispatch();

    return useMutation({
        mutationFn: sendEmailApi,
        onSuccess: (data) => {
            showMessage('success', data.message);
            dispatch(cleanFile())
        },
        onError: (err) => {
            handleError(err);
        }
    });
};