import React, { useEffect, useMemo } from 'react';
import { Alert, Collapse, Drawer, Form, Input, message, Select, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from "react-quill";
import { IoMdClose } from "react-icons/io";
import { useUploadFileApi } from '../../apis/filesApi';
import { cleanFile, filterFile } from '../../redux/storage/action';
import ButtonCustom from '../../components/common/Button/ButtonCustom';
import { useSendEmailApi } from '../../apis/teacherApi';
import 'react-quill/dist/quill.snow.css';


const { Option } = Select;
const { Panel } = Collapse;


const BulkStaffEmail = ({
    type,
    forWhom,
    allEmails,
    isSendComposedEmailModalVisible,
    setIsSendComposedEmailModalVisible,
}) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const baseURL = 'https://khrouch.s3.ap-south-1.amazonaws.com/';

    const {
        mutate: uploadMutate,
        isSuccess, isPending:
        isUploading
    } = useUploadFileApi();

    const {
        mutate: sendEmailMutate,
        isSuccess: isSendSuccess,
        isPending: isSending
    } = useSendEmailApi();

    const body = useMemo(() => ({
        type: type,
        forWhom: forWhom

    }), [forWhom, type])

    const imageObj = {};
    const keys = ["s3Url", "s3Url2", "s3Url3", "s3Url4", "s3Url5"];

    const imageLinks = useSelector(state => state.storageReducer.imageLink);
    console.log(imageLinks)
    imageLinks?.forEach((link, index) => {
        if (link) {
            imageObj[keys[index]] = link;
        }
    });

    const imgLen = Object.keys(imageObj).length;


    const handleFileUpload = (e) => {
        const formData = new FormData();
        formData.append("agentAgreement", e?.target?.files?.[0]);

        uploadMutate(formData)
        if (isSuccess) { form.setFieldValue("imageUrl", null) }
    }



    const handleSendMail = (data) => {
        message.info("Your email is being sent. Please wait a few seconds...");

        // algorithm at the bottom of page
        let result = data?.message?.replace(/^<p>/, '').replace(/<\/p>$/, '');
        result = result.replace(/<p>/g, '');
        result = result.replace(/<\/p>(?!<br>)/g, '<br>').replace(/<\/p>/g, '');

        const apibody = {
            ...data,
            ...imageObj,
            "senderName": "KhrouchCRM",
            "replyToEmail": "no-reply@khrouch.com",
            "ids": data?.bcc.filter(val => typeof (val) === "number"),
            message: result,
            bcc: data.bcc?.filter(val => typeof (val) !== "number"),
            ...body
        }

        sendEmailMutate(apibody)

        if (isSendSuccess) {
            form.resetFields();
            setIsSendComposedEmailModalVisible(false);
        }
    };


    const handleRemoveImage = (index) => {
        dispatch(filterFile(index))
    };


    const clzEmail = "test@test.com"

    // Important
    useEffect(() => {
        return () => { dispatch(cleanFile()); }
    }, [dispatch])

    return (
        <>
            <Drawer
                title="Compose Email"
                width={720}
                onClose={() => setIsSendComposedEmailModalVisible(false)}
                open={isSendComposedEmailModalVisible}
            >
                <Form
                    layout="vertical"
                    initialValues={{
                        email: [clzEmail],
                        bcc: allEmails?.map(val => val.id)
                    }}
                    onFinish={handleSendMail}
                    colon={true}
                    form={form}
                    className={"drawerStyle"}
                >

                    <Form.Item
                        label="To"
                        name={"email"}
                        style={{ width: "100%" }}
                        rules={[{ required: true, message: "Please enter email address you want to send to!", },]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select Emails"
                            showSearch
                            // labelInValue
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            <Option value={clzEmail}>{clzEmail}</Option>
                        </Select>
                    </Form.Item>

                    <Collapse
                        size="small"
                        className="mb-4"
                        bordered={false}
                        defaultActiveKey={['1']}
                    >

                        <Panel header="BCC" key="1">

                            <Form.Item
                                label="Bcc"
                                name={"bcc"}
                                className="w-full"
                                rules={[{ required: true, message: "Please enter email address you want to send to!", },]}
                            >
                                <Select
                                    mode="tags"
                                    placeholder="Select Emails"
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {allEmails?.map((val) => (
                                        <Option key={val.id} value={val.id}>{val.email}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Panel>
                    </Collapse>


                    <Form.Item
                        label="Subject"
                        name={"subject"}
                        style={{ width: "100%" }}
                        rules={[{ required: true, message: "Please enter a subject!", },]}
                    >
                        <Input
                            placeholder="Subject"
                        />
                    </Form.Item>


                    <Form.Item
                        label="Message"
                        name={"message"}
                        style={{ width: "100%" }}
                        rules={[{ required: true, message: "Please enter a message!", },]}
                    >
                        <ReactQuill
                            className="h-80 bg-white mb-8 p-2 border-[#f5f5f5]"
                            modules={{
                                toolbar: {
                                    container: [
                                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                        ["bold", "italic", "underline"],
                                        [{ list: "ordered" }, { list: "bullet" }],
                                        [{ align: [] }],
                                        ["link"],
                                        ["clean"],
                                        [{ color: [] }],
                                    ],
                                },
                            }}
                            theme="snow"
                        />
                    </Form.Item>



                    <div className="space-y-5">
                        {imgLen ? (
                            <div className="flex flex-col justify-start items-start gap-2 w-full">
                                {Object.keys(imageObj).map((key, index) => (
                                    <div className="w-full" key={key}>
                                        <Tag className="w-full py-2 flex justify-between items-center">
                                            <p>
                                                <a
                                                    href={`${baseURL}${imageObj[key]}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-sm text-blue-600"
                                                >
                                                    {imageObj[key]}
                                                </a>
                                            </p>
                                            <IoMdClose
                                                onClick={() => handleRemoveImage(index)}
                                                className="cursor-pointer text-xl text-red-500"
                                            />

                                        </Tag>
                                    </div>
                                ))}
                            </div>
                        ) : null}

                        {imgLen >= 5 &&
                            <div className="">
                                <Alert message="Maximum File upload limit reached" type="warning" />
                            </div>
                        }

                        <div className="pt-2">
                            <label
                                htmlFor="imageUrl"
                                className={`${(imgLen >= 5 || isUploading) ? "cursor-not-allowed bg-slate-200 text-black" : "text-white cursor-pointer bg-primary-gradient hover:shadow-[0_0_2px_1px_rgba(64,150,255,0.9)]"}  px-4 py-2 rounded-md transition-all duration-150`}
                            >
                                Upload File
                                <Form.Item className="hidden" label={"File attachment"} >
                                    <Input id="imageUrl" disabled={imgLen >= 5 || isUploading} type="file" onChange={handleFileUpload} />
                                </Form.Item>
                            </label>
                        </div>


                        <div>
                            <ButtonCustom
                                disabled={isUploading}
                                type="submit"
                            >Submit
                            </ButtonCustom>
                        </div>
                    </div>

                </Form>
            </Drawer>
        </>
    );
};
export default BulkStaffEmail;