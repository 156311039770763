import Cookies from "js-cookie";
import axios from 'axios';
import { message } from "antd";


const BASE_URL = 'http://195.35.9.71:8080/smartrto';


export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
});

axiosInstance.interceptors.request.use(
    config => {
        const token = Cookies.get("access_token_teacher");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);



const handleUnauthorizedError = async (error) => {
    if (error.response && error.response.status === 401) {
        // the browser back button now takes you to assigned location
        window.location.assign('/login/college');
        window.location.replace('/login/college');
        Cookies.remove("access_token_teacher")
        Cookies.remove("tchrId")
        message.info("Your session has expired. Please log in again to continue.")
    }
    else if (error.response && error.response.status === 500) {
        return Promise.reject(error.response);
    }
    else return Promise.reject(error);
};

const handleResponse = (response) => {
    if (response.status === 200 && response.data?.error?.code === 500) {
        message.info(response.data.error.message || "A server error occurred.");
        return response
    }
    return response;
};


axiosInstance.interceptors.response.use(
    (response) => handleResponse(response),
    (error) => handleUnauthorizedError(error)
);

export const axiosTokenless = axios.create({
    baseURL: BASE_URL,
});