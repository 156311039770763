import { Card, Table, Tag } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../components/PageStyle/PageHeader";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import PaginationElement from "../../../components/PaginationElement/PaginationElement";
import { useMatrixApi, useTeacherDetailApi } from "../../../apis/teacherApi";


const Matrix = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const pageCount = 0;
    const sort = "DESC";
    const sortParameter = "id";


    const { data: staff } = useTeacherDetailApi();

    const { data, isPending } = useMatrixApi({
        currentPage: currentPage - 1,
        pageCount: pageCount,
        pageSize: pageSize,
        sort: sort,
        sortParameter: sortParameter,
        teacherId: staff.id
    }) || {};

    const teacherMatrixList = data?.data || [];
    const totalPage = data?.totalPage || 0;


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 100,
            align: "center",
        },
        {
            title: "Subject",
            dataIndex: "subjectNames",
            width: 250,
            align: "center",
            render: (sub) => (
                <div className="space-y-1.5">
                    {sub?.length ? sub.map(val => <Tag key={val}>{val}</Tag>) : null}
                </div>
            )
        },
        {
            title: "Knowledge Level",
            dataIndex: "knowledgeLevel",
            width: 250,
            align: "center",
        }
    ];


    return (
        <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
            <PageHeader>
                <div className="fxRAc" style={{ gap: "1rem" }}>
                    <div className="title">Teacher Matrix</div>
                </div>
            </PageHeader>
            <div
                className="fxC"
                style={{
                    marginTop: "1rem",
                }}
            >
                {isPending ? (
                    <div className="overflow-hidden">
                        <TableSkeleton />
                    </div>
                ) : (
                    <Table
                        columns={columns.map(val => ({
                            ...val,
                            title: <div className="whitespace-nowrap min-w-fit px-4">{val.title}</div>,
                        }))}
                        dataSource={teacherMatrixList}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: true }}
                        bordered
                    />
                )}
            </div>
            <PaginationElement
                currentPage={currentPage}
                pageSize={pageSize}
                totalPage={totalPage}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
            />

        </Card>
    );
};

export default Matrix;
