import { Timeline } from 'antd'
import dayjs from 'dayjs'
import React from 'react'

export const TimelineUI = ({ timelineItems }) => {

    const sortedTime = timelineItems?.sort((a, b) => {
        const dateA = dayjs(a.date);
        const dateB = dayjs(b.date);

        if (!dateA.isValid()) return 1;
        if (!dateB.isValid()) return -1;

        return dateB.valueOf() - dateA.valueOf();
    });

    return (
        <div className='p-6 pb-0 overflow-y-auto'
            style={{
                height: "450px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1px",
                flexWrap: "wrap",
            }}
        >
            <Timeline>
                {sortedTime?.map((item) => (
                    <Timeline.Item key={item.key}>
                        <div className="flexColumnwithoutStyle">
                            <div style={{ fontWeight: "200" }}>{item.date}</div>
                            <div style={{ fontWeight: "bold" }}>{item.title}</div>
                            {item.activityStatus !== null &&
                                item.activityStatus !== "" &&
                                item.activityStatus !== undefined && (
                                    <div>Status: {item.activityStatus}</div>
                                )}
                            {item.activityBy !== null &&
                                item.activityBy !== "" &&
                                item.activityBy !== undefined && (
                                    <div>Activity By: {item.activityBy}</div>
                                )}
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>

        </div>)
}
