export const storeData = (data) => ({
    type: 'STORE_USER_RECORDS',
    payload: data
})

export const cleanData = () => ({
    type: 'CLEAN_USER_RECORDS',
})
/****************************************************************** */
export const storeFile = (data) => ({
    type: 'STORE_FILE_OR_IMAGE_INFO',
    payload: data
})

export const cleanFile = () => ({
    type: 'CLEAN_FILE_OR_IMAGE_INFO',
})

export const filterFile = (idx) => ({
    type: 'REMOVE_SPECIFIC_FILE',
    payload: idx
})