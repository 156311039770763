import React from "react";

const TableSkeleton = () => {
    return (
        <table className="min-w-full divide-y divide-gray-200 animate-pulse bg-gray-100">
            <tbody>
                {Array.from({ length: 5 }).map((_, rowIndex) => (
                    <tr key={rowIndex} className="animate-pulse">
                        {Array.from({ length: 8 }).map((_, colIndex) => (
                            <td key={colIndex} className="px-4 py-2">
                                <div className="h-4 bg-gray-300 w-40 rounded"></div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableSkeleton;
