import React from 'react'

const Timetable = () => {
    return (
        <div>Timetable</div>
    )
}

export default Timetable

// http://195.35.9.71:8080/smartrto/v1/api/timetable/class/get-by-filter?currentPage=0&pageCount=0&pageSize=10&sort=DESC&sortParameter=id&filterType=ByTeacher&filterValue=84