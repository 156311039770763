import './App.css';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import TeacherDetails from './pages/Teacher/TeacherDetails';
import Login from './pages/Auth/Login';
import ClzUrl from './pages/Auth/ClzUrl';


function App() {

  return (
    <div className="flex flex-col min-h-screen text-secondary">


      <Routes>
        <Route path='/login/college' element={<ClzUrl />} />
        <Route path='/login' element={<Login />} />

        {/* Private Routes */}
        <Route element={<RequireAuth />}>
          <Route index element={<TeacherDetails />} />
        </Route>


      </Routes>
    </div>
  );
}

export default App;
