import dayjs from "dayjs";
import { message } from "antd";


/**
 * @param {string} date
 * @return {string}
 */
export const displayDate = (date) => {
    return date ? dayjs(date).format("DD/MM/YYYY") : "N/A";
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
}


export const showMessage = (type, msg) => {
    if (type === 'success') {
        message.success(msg);
    } else if (type === 'error') {
        message.error(msg);
    }
};


export const handleError = (error) => {
    let message = error.response?.data?.message || 'An error occurred. Please try again.';
    if (error.response?.data?.message === "Invalid access token") {
        message = "Your session has expired. Please log in again to continue."
    }
    showMessage('error', message);
};

/**
 * 
 * @param {string} base64 
 * @param {string} name 
 */
export const downloadPdfFile = (base64, name) => {
    const blob = base64ToBlob(base64);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name ?? "pdffile"}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

/**
 * 
 * @param {string} base64Data 
 * @returns 
 */
const base64ToBlob = (base64Data) => {
    if (base64Data) {
        const binaryData = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        return new Blob([uint8Array], { type: "application/pdf" });
    } else return null;
};
