import { Card, Image, Tabs } from "antd";
import React, { useEffect } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";
import { client } from "../../assets";
import { useTeacherDetailApi } from "../../apis/teacherApi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import DetailSkeleton from "../../components/Skeleton/DetailSkeleton";
import BulkStaffEmail from '../Email/BulkStaffEmail'
import Profile from "./TeacherTabs/Profile";
import ActivityLog from "./TeacherTabs/ActivityLog";
import Matrix from "./TeacherTabs/Matrix";
import Timetable from "./TeacherTabs/Timetable";
import Diary from "./TeacherTabs/Diary";
import Documents from "./TeacherTabs/Documents";
import LeaveInfo from "./TeacherTabs/LeaveInfo";
import ProfessionalDevelopment from "./TeacherTabs/ProfessionalDevelopment";
import ButtonCustom from "../../components/common/Button/ButtonCustom";
import { LogoutOutlined } from "@ant-design/icons";


const TeacherDetails = () => {

    const navigate = useNavigate();


    const staffTabList = [
        {
            key: "activityLog",
            label: "Activity Log",
            children: <ActivityLog />
        },
        {
            key: "profile",
            label: "Profile",
            children: <Profile />
        },
        {
            key: "teacherMatrix",
            label: "Teacher Matrix",
            children: <Matrix />
        },
        {
            key: "timetable",
            label: "Timetable",
            children: <Timetable />
        },
        {
            key: "diary",
            label: "Diary",
            children: <Diary />
        },
        {
            key: "documents",
            label: "Documents",
            children: <Documents />
        },
        {
            key: "leaveInfo",
            label: "Leave Info",
            children: <LeaveInfo />
        },
        {
            key: "professionalDevelopment",
            label: "Professional Development",
            children: <ProfessionalDevelopment />
        },
    ];

    const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState("profile");

    const { data: teacherData, isPending } = useTeacherDetailApi();

    useEffect(() => {
        const savedTabKey = localStorage.getItem("activeTabKey");
        if (savedTabKey) {
            setActiveTabKey(savedTabKey);
        }
    }, []);

    const handleTabChange = (key) => {
        setActiveTabKey(key);
        localStorage.setItem("activeTabKey", key);
    };

    const handleLogout = () => {
        navigate("/login/college")
        Cookies.remove("access_token")
        Cookies.remove("tchrId")
    }


    return (
        <div className="p-4 min-h-screen">
            {isPending ? (
                <DetailSkeleton />
            ) : (
                <Card style={{ borderRadius: "3px" }} className="drawerStyle">
                    <div className="flex justify-between items-start gap-4">

                        <div className="flex justify-start items-center gap-4">
                            <Image
                                src={teacherData?.staffImage ?? client}
                                onError={(e) => e.target.src = client}
                                className="w-full h-full object-cover"
                                height={160}
                                width={160}
                            />

                            <div className="flex justify-center items-start flex-col gap-2" >

                                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                                    {[
                                        teacherData?.firstName,
                                        teacherData?.lastName
                                    ].filter(Boolean).join(' ')}{teacherData?.id ? ` (ID: ${teacherData.id})` : ''}
                                </div>

                                <div className="flex flex-col justify-center items-start gap-1 text-[#aaa]">
                                    <span className="fxRAc" style={{ gap: "0.2rem" }}>
                                        <AiOutlinePhone size={20} />
                                        {teacherData?.phoneNo}
                                    </span>

                                    <span className="fxRAc" style={{ gap: "0.2rem" }}>
                                        <GoLocation className="text-xl" />
                                        {[
                                            teacherData?.address,
                                            teacherData?.city,
                                            teacherData?.state,
                                            teacherData?.country,
                                            teacherData?.postCode
                                        ].filter(Boolean).join(', ')}
                                    </span>
                                    <span className="fxRAc" style={{ gap: "0.2rem" }}>
                                        <AiOutlineMail size={16} />
                                        {teacherData?.email}
                                    </span>
                                </div>


                                <div className="flex justify-center items-center gap-3">

                                    <button
                                        className="emailAndMessage"
                                        onClick={() => { }}
                                    >
                                        <BsChatLeftText size={10} />
                                        <span>Message</span>
                                    </button>

                                    {/* <button
                                        className="emailAndMessage"
                                        onClick={() => { setIsSendComposedEmailModalVisible(true) }}
                                    >
                                        <AiOutlineMail />
                                        <span>Email</span>
                                    </button> */}

                                </div>

                            </div>
                        </div>

                        <div>
                            <ButtonCustom
                                onClick={handleLogout}
                                className={"flex justify-center items-center gap-2 text-sm"}
                            >
                                <LogoutOutlined />
                                <div>Logout</div>
                            </ButtonCustom>
                        </div>

                    </div>

                    <Tabs
                        activeKey={activeTabKey}
                        onChange={handleTabChange}
                        items={staffTabList}
                        destroyInactiveTabPane={true}
                    />

                </Card>
            )}

            {/* <BulkStaffEmail
                type={"MANUAL"}
                forWhom={"Staff"}
                allEmails={[
                    { id: teacherData?.id, email: teacherData?.email }
                ]}
                isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
                setIsSendComposedEmailModalVisible={setIsSendComposedEmailModalVisible}
            /> */}
        </div>
    );
};

export default TeacherDetails;