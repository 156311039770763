const ButtonCustom = ({
    disabled = false,
    onClick,
    type = 'button',
    className,
    children,
    onMouseEnter,
    onMouseLeave
}) => {
    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`${disabled ? "cursor-wait" : "cursor-pointer bg-primary-gradient hover:shadow-[0_0_2px_1px_rgba(64,150,255,0.9)]"} px-4 py-2 rounded-md text-white transition-all duration-150 
             disabled:bg-slate-200 disabled:text-black ${className}`}
        >
            {children && children}
        </button>
    )
}

export default ButtonCustom