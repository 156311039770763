import { Card, Table, Tag } from "antd";
import React, { useState } from "react";
import PageHeader from "../../../components/PageStyle/PageHeader";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import { displayDate } from "../../../utils/helpers";
import { useProDevApi, useTeacherDetailApi } from "../../../apis/teacherApi";
import PaginationElement from "../../../components/PaginationElement/PaginationElement";
import dayjs from "dayjs";



const ProfessionalDevelopment = () => {


    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const pageCount = 0;
    const sort = "DESC";
    const sortParameter = "id";

    const { data: staff } = useTeacherDetailApi();

    const { data, isPending } = useProDevApi({
        currentPage: currentPage - 1,
        pageCount: pageCount,
        pageSize: pageSize,
        sort: sort,
        sortParameter: sortParameter,
        id: staff?.id,
    }) || {};

    const proDevList = data?.data || [];
    const totalPage = data?.totalPage || 0;


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 100,
            align: "center",
        },
        {
            title: "Name",
            dataIndex: "eventName",
            width: 150,
            align: "center",
        },
        {
            title: "Category",
            dataIndex: "category",
            width: 150,
            align: "center",
        },
        {
            title: "Event Name",
            dataIndex: "eventName",
            width: 150,
            align: "center",
        },
        {
            title: "Organized By",
            dataIndex: "organisedBy",
            width: 150,
            align: "center",
        },

        {
            title: "Activity Name",
            dataIndex: "activityName",
            width: 150,
            align: "center",
        },
        {
            title: "Event Date",
            dataIndex: "eventStartedOn",
            width: 150,
            align: "center",
            render: (date) => displayDate(date)
        },
        {
            title: "Ended Date",
            dataIndex: "eventEndedOn",
            width: 200,
            align: "center",
            render: (date) => displayDate(date)
        },
        {
            title: "No. of Days",
            dataIndex: "noOfDays",
            width: 100,
            align: "center",
            render: (val) => {
                const diffDays = dayjs(val?.eventEndedOn).diff(dayjs(val?.eventStartedOn), 'day');
                return `${diffDays} days`;
            }
        },
        {
            title: "CPD Points",
            dataIndex: "cpdPoints",
            width: 150,
            align: "center",
        },
        {
            title: "File Attachment",
            dataIndex: "evidenceImage",
            width: 200,
            align: "center",
            render: (val) =>
                val ?
                    <a target="_blank" rel="noreferrer" href={val?.evidenceImage}>view</a> : "N/A",
        },
        {
            title: "Comments",
            dataIndex: "comments",
            width: 500,
            align: "center",
        },
        {
            title: "Verify",
            dataIndex: "isVerified",
            width: 150,
            align: "center",
            fixed: "right",
            render: (val) => (
                val ?
                    <Tag className="active-tag">Verified</Tag> : <Tag className="inactive-tag">Not verified</Tag>
            )
        },
    ];



    return (
        <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
            <PageHeader>
                <div className="fxRAc" style={{ gap: "1rem" }}>
                    <div className="title">Professional Development</div>
                </div>
            </PageHeader>
            <div
                className="fxC"
                style={{
                    marginTop: "1rem",
                    overflow: "hidden"
                }}
            >
                {isPending ? (
                    <div className="overflow-hidden">
                        <TableSkeleton />
                    </div>
                ) : (
                    <Table
                        columns={columns.map(val => ({
                            ...val,
                            title: <div className="whitespace-nowrap min-w-fit px-4">{val.title}</div>,
                        }))}
                        dataSource={proDevList}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: true }}
                        bordered
                    />
                )}
                <PaginationElement
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalPage={totalPage}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                />
            </div>

        </Card>
    );
};

export default ProfessionalDevelopment;
