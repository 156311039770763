import { useNavigate } from "react-router-dom";
import { axiosTokenless } from "./baseConfig";
import { useMutation, useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { handleError, showMessage } from "../utils/helpers";
import { message } from "antd";
import axios from "axios";



const loginUser = async (body) => {

    const queryParams = new URLSearchParams();
    queryParams.set("companyUrl", "prasantasujal");

    const url = `/v1/api/college/college-login?${queryParams}`;
    const response = await axiosTokenless.post(url, body);
    return response.data;
};

export const useLogin = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: loginUser,
        onSuccess: (data) => {
            if (data?.error && data.error?.code === 500) {
                message.error(data.error?.message);
            } else {
                showMessage('success', data.message);
                Cookies.set("access_token_teacher", data?.data?.token)
                Cookies.set("tchrId", data?.data?.employeeInformation?.id)
                navigate(`/`, { replace: true });
            }
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/******************************************************************************************************************** */

const loginCollegeUrl = async (params) => {
    const url = `/v1/api/college/validate-college-url`;
    const response = await axiosTokenless.get(url, { params });
    return response.data?.data;
};

export const useLoginCollegeUrl = (params) => {
    return useQuery({
        queryFn: () => loginCollegeUrl(params),
        queryKey: ["loginCollegeUrl", params],
        enabled: !!params.loginCollegeUrl,
        gcTime: 0.1
    })
};
/******************************************************************************************************************** */

const userIp = async () => {
    const url = `http://api.ipify.org?format=json`;
    const response = await axios.get(url);
    return response.data;
};

export const useUserIp = () => {
    return useQuery({
        queryFn: () => userIp(),
        queryKey: ["userIp"]
    })
};
