import { Carousel, Form, Input } from 'antd'
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import React from 'react'
import { carousel1, carousel2, carousel3 } from '../../assets';
import ButtonCustom from '../../components/common/Button/ButtonCustom';
import { useLogin, useUserIp } from '../../apis/authApi';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();

    const { mutate: loginMutate, isPending } = useLogin();

    const { data: ipAddress } = useUserIp()

    const handleSubmitLogin = (val) => {
        loginMutate({
            ...val,
            ipAddress: ipAddress?.ip
        })
    }

    return (
        <div className='flex justify-center items-stretch w-full h-screen'>
            <div className='w-[70%] flex flex-col justify-center items-center'>
                <p className='text-center text-xl font-medium mb-6'>
                    Login to your account!
                </p>


                <Form
                    layout="vertical"
                    style={{ maxWidth: "20rem", width: "100%" }}
                    onFinish={handleSubmitLogin}
                >
                    <Form.Item
                        name={"userName"}
                        rules={[{ required: true, message: "Please enter username!", },]}
                        className="rounded-md"
                    >
                        <Input
                            placeholder="Username"
                            size="large"
                            prefix={<UserOutlined />}
                        />
                    </Form.Item>


                    <Form.Item
                        name={"password"}
                        rules={[{ required: true, message: "Please enter Password!", },]}
                    >
                        <Input.Password
                            placeholder="Password"
                            size="large"
                            prefix={<LockOutlined />}
                        />
                    </Form.Item>

                    <div className='flex justify-center items-center gap-10'>
                        <button
                            onClick={() => navigate("/login/college")}
                            disabled={isPending}
                            className={`${isPending ? "cursor-wait" : "cursor-pointer"} bg-slate-200 text-black hover:shadow-[0_0_2px_1px_rgba(200,200,200,0.9)] px-4 py-2 rounded-md transition-all duration-150 `}
                        >
                            BACK
                        </button>

                        <ButtonCustom
                            type='submit'
                            disabled={isPending}
                        >
                            SIGN IN
                        </ButtonCustom>
                    </div>

                </Form>
            </div>

            <div className='w-[30%]'>
                <Carousel autoplay={true}>
                    <div className='w-full h-screen'>
                        <img
                            className="text-white leading-[160px] text-center bg-[#364d79] object-contain w-full h-full"
                            src={carousel1}
                            alt="Carousel1"
                        />
                        <h1 className="text-white text-center leading-loose">
                            Manage Data
                        </h1>
                    </div>
                    <div className='w-full h-screen'>
                        <img
                            className="text-white leading-[160px] text-center bg-[#364d79] object-contain w-full h-full"
                            src={carousel2}
                            alt="Carousel2"
                        />
                        <h1 className="text-white leading-[160px] text-center">
                            Automation
                        </h1>
                    </div>
                    <div className='w-full h-screen'>
                        <img
                            className="text-white leading-[160px] text-center bg-[#364d79] object-contain w-full h-full"
                            src={carousel3}
                            alt="Carousel3"
                        />
                        <h1 className="text-white leading-[160px] text-center">
                            Reporting
                        </h1>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}

export default Login