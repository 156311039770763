import { Card, Table } from "antd";
import React, { useState } from "react";
import PaginationElement from "../../../components/PaginationElement/PaginationElement";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import PageHeader from "../../../components/PageStyle/PageHeader";
import { displayDate } from "../../../utils/helpers";
import { useLeaveInfoApi, useTeacherDetailApi } from "../../../apis/teacherApi";


const LeaveInfo = () => {


    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const pageCount = 0;
    const sort = "DESC";
    const sortParameter = "id";

    const { data: staff } = useTeacherDetailApi();

    const { data, isPending } = useLeaveInfoApi({
        currentPage: currentPage - 1,
        pageCount: pageCount,
        pageSize: pageSize,
        sort: sort,
        sortParameter: sortParameter,
        id: staff?.id,
    }) || {};

    const leaveInfoList = data?.data || [];
    const totalPage = data?.totalPage || 1;


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 100,
            align: "center",
        },
        {
            title: "Staff",
            dataIndex: "staffName",
            width: 100,
            align: "center",
        },
        {
            title: "Semester",
            dataIndex: "semester",
            width: 150,
            align: "center",
            render: (val) => val.name
        },
        {
            title: "Request Date",
            dataIndex: "requestDate",
            width: 150,
            align: "center",
        },
        {
            title: "Leave From",
            dataIndex: "leaveFrom",
            width: 150,
            align: "center",
            render: (date) => displayDate(date)
        },

        {
            title: "Leave To",
            dataIndex: "leaveTo",
            width: 150,
            align: "center",
            render: (date) => displayDate(date)
        },
        {
            title: "Leave Details",
            dataIndex: "details",
            width: 150,
            align: "center",
        },
    ];




    return (
        <Card bordered={true} style={{ marginTop: "2rem", borderRadius: "3px" }}>
            <PageHeader>
                <div className="fxRAc" style={{ gap: "1rem" }}>
                    <div className="title">Leave Info</div>
                </div>
            </PageHeader>
            <div
                className="fxC"
                style={{
                    marginTop: "1rem",
                }}
            >
                {isPending ? (
                    <div className="overflow-hidden">
                        <TableSkeleton />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={leaveInfoList}
                        pagination={false}
                        scroll={{ x: 1000 }}
                    />
                )}
                <PaginationElement
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalPage={totalPage}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </Card>
    );
};

export default LeaveInfo;
