import { useMutation } from "@tanstack/react-query";
import { downloadPdfFile, handleError, showMessage } from "../utils/helpers";
import { axiosInstance } from "./baseConfig";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { storeFile } from "../redux/storage/action";


const downloadCertApi = async (id) => {
    const url = `/v1/api/offers/applicant/course/certificate/download-certificate/${id}`;
    const response = await axiosInstance.post(url);
    return response.data;
};

export const useDownloadCertApi = () => {

    return useMutation({
        mutationFn: downloadCertApi,
        onSuccess: (data) => {
            showMessage('success', "File downloaded successfully");
            downloadPdfFile(data, "certificate_register")
        },
        onError: (err) => {
            handleError(err);
        }
    });
};
/****************************************************************************************************** */



const uploadFileApi = async (formData) => {
    const url = `/v1/api/college/email/upload-document`;
    const response = await axiosInstance.post(url, formData);
    return response.data;
};

export const useUploadFileApi = () => {

    const dispatch = useDispatch();

    return useMutation({
        mutationFn: uploadFileApi,
        onSuccess: (data) => {
            showMessage('success', data.message);
            dispatch(storeFile(data.user))
        },
        onError: (err) => {
            handleError(err);
        }
    });
};
